.react-datepicker-ignore-onclickoutside {
    width: 100% !important;
}

.react-datepicker__input-container input {
    width: 100%;
}

.main-container {
    color: #34495e;
}

.table-border {
    border: 1px solid #d6dbdf;
}

.questionview {
    animation: fadeInAnimation ease 1s;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sty {
    font-weight: 500 !important;
}

.content-tab:after {
    content: " ";
    position: absolute;
    right: 50%;
    bottom: -15px;
    border-top: 15px solid #4285f4;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    display: block;
}

#background {
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(7px);
    z-index: -1;
}

.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.player-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 250px;
    width: 425px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #8d97ba;
}

#thumbnail {
    position: absolute;
    object-fit: fill;
    height: 100%;
    width: 100%;
    top: -10%;
    transition: 1s;
    z-index: 3;
}

.box {
    position: absolute;
    height: 250px;
    width: 100%;
    background: #0d3c5e;
    z-index: 4;
}

.play-pause {
    grid-area: one;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 15px;
}

.fa-pause-circle {
    filter: invert(1);
    cursor: pointer;
    margin-top: 15px;
    display: none;
}

#play,
#prev-track,
#next-track,
#back10,
#forward10,
#stop {
    filter: invert(1);
    cursor: pointer;
    margin-top: 15px;
}

.track-info {
    padding: 30px 25px 5px;
    text-align: center;
    height: 70px;
}

#track-artist {
    color: #948ca5;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
}

#track-title {
    color: white;
    font-family: "Lato", sans-serif;
    font-size: 1rem;
}

.next-prev {
    grid-area: three;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    margin: 0 auto;
}

.progress-bar {
    grid-area: four;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

#progressBar {
    height: 5px;
    background: white;
    width: 85%;
    outline: none;
    border-radius: 30px;
}

#progressBar::-webkit-slider-thumb {
    appearance: none;
    height: 11px;
    width: 11px;
    outline: none;
    background: #f94c57;
    border-radius: 30px;
    cursor: pointer;
}

.track-time {
    grid-area: five;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    margin: 10px auto;
}

#currentTime {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    color: white;
}

#durationTime {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    color: #948ca5;
}

.active-chapter {
    background-color: rgba(238, 243, 248, 1);
}

.custom-input::placeholder {
    color: #3c4043;
    opacity: 1;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    /* background-color: #47ccde ; */
    border-radius: 3px;
    border: 1px solid #aeb6bf;
    color: #5dade2;
}

.pagination>li>a {
    border: 0;
    font-size: 18px;
    padding: 5px 15px;
    outline: none;
    cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    /* background-color: #47ccde ; */
    border-color: #aeb6bf;
    outline: none;
}

.pagination>li>a,
.pagination>li>span {
    color: #2471a3;
    font-weight: bold;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
}

.prevButton {
    margin-right: 10px;
}

.nexButton {
    margin-left: 10px;
}

.rhap_theme-color {
    color: red;
}

.rhap_background-color {
    color: red;
}

.rhap_bar-color {
    color: red;
}

.folderTab:hover {
    background-color: #f2f4f4;
}

.activeFolderTab {
    background-color: #3498db;
    color: white;
}

#playerBox:focus {
    outline: 0px solid transparent;
}

.courseItem:hover {
    background: #f94c57;
}

.file-tab:hover {
    background-color: #d6dbdf;
}

/* .mainSideMenu {
  display:none;
  width: 0;
  transition: 1s;
}
.menuButton:active .mainSideMenu{
  width: 200px;
  display:block;
} */

#scroll-bar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(103, 105, 113);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#scroll-bar::-webkit-scrollbar {
    width: 6px;
    background-color: rgb(199, 203, 210);
}

#scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(103, 105, 113);
    background-color: #555;
}
.CustomScrollBar::-webkit-scrollbar-track {
    background-color: #D9D9D9;
}

.CustomScrollBar::-webkit-scrollbar {
    width: 5px;
    background-color: #D9D9D9;
}

.CustomScrollBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 6px;
    background-color: #A39090;
}

.active-folder {
    background-color: #ebedef;
    /* color: white; */
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.teacherCarousalCard {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


/*------------------ Schedule Page  ------------------*/

.events-heading-block {
    padding: 10px;
    margin: 0 auto 20px;
    background-color: #7a8dff;
    color: #fff;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
}

.classes-card {
    padding: 20px;
}

.today-events-block {
    width: 100%;
}

.today-events-block .event-card-block {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}

.event-card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 3px 3px 4px rgb(69 89 210 / 10%);
}

.event-card .classes-card>div {
    display: inline-block;
}

.event-card .classes-card .status-tag {
    width: 30%;
    text-align: right;
}

.event-card .classes-card .status-tag label {
    font-size: 14px;
    background-color: #c8d0ff;
    padding: 6px 15px;
    border-radius: 50px;
    color: #687df9;
}

.event-card .classes-card .course-heading {
    width: 70%;
    vertical-align: top;
}

.event-card .classes-card .course-heading h4 {
    font-weight: 700;
    margin-bottom: 5px;
    color: #4559d2;
}

.event-card .classes-card .course-heading p {
    color: #8b93d9;
    font-size: 14px;
}

.event-card .date-time-sec {
    border-top: 1px solid #cbd3ff;
    padding: 5px 20px 10px;
}

.event-card .date-time-sec>div {
    display: inline-block;
    width: 50%;
}

.event-card .date-time-sec .time-sec {
    text-align: right;
}

.event-card .date-time-sec .date-sec span,
.event-card .date-time-sec .time-sec span {
    font-size: 12px;
    color: #8b93d9;
    margin-bottom: 3px;
}

.event-card .date-time-sec .date-sec p,
.event-card .date-time-sec .time-sec p {
    font-size: 14px;
    font-weight: 600;
}

.event-card .date-time-sec .date-sec p,
.event-card .date-time-sec .time-sec p {
    color: #7085ff;
}


.swapped-class .event-card {
    background-color: #fff4e1;
    border-color: #ffce7a;
}

.swapped-class .event-card .swapped-member-sec {
    display: block;
    margin-top: 10px;
}

.swapped-class .classes-card .status-tag label {
    background-color: #ffcd72;
    color: #8f5c00;
}

.swapped-class .event-card .date-time-sec {
    border-color: #ffce7a;
}

.swapped-class .event-card .classes-card .course-heading h4 {
    color: #8f5c00;
}

.swapped-class .event-card .classes-card .course-heading p {
    color: #917f62;
}

.swapped-class .event-card .date-time-sec .date-sec span,
.swapped-class .event-card .date-time-sec .time-sec span {
    color: #917f62;
}

.swapped-class .event-card .date-time-sec .date-sec p,
.swapped-class .event-card .date-time-sec .time-sec p {
    color: #c98100;
}

.swapped-section {
    align-items: center;
    display: flex;
    margin-top: 10px;
}

.swapped-member-sec .swapped-whith-sec p {
    font-size: 14px;
    color: #cf9334;
}

.swapped-member-sec .swapped-whith-sec .swapped-user {
    width: 80px;
}

.swapped-member-sec .swapped-whith-sec .swapped-user img {
    width: 60px;
    height: 60px;
    display: inline-block;
    margin-right: 20px;
    background-color: #e5bf7c;
}

.swapped-member-sec .swapped-whith-sec .swapped-user label {
    font-weight: 600;
    color: #8f5c00;
    font-size: 14px;
}

.swapped-member-sec .swapped-whith-sec .swapped-date-sec {
    font-size: 14px;
    font-weight: 600;
}

.swapped-member-sec .swapped-whith-sec .swapped-date-sec p {
    font-size: 14px;
}

.swapped-member-sec .swapped-whith-sec .swapped-date-sec p {
    display: inline-block;
    margin-right: 20px;
    font-weight: 500;
}

.swapped-member-sec .swapped-whith-sec .swapped-date-sec label {
    display: block;
    color: #8f5c00;
}


.progress-class .event-card {
    background-color: #ffe9da;
    border-color: #ffa568;
}

.progress-class .event-card .swapped-member-sec {
    display: block;
    margin-top: 10px;
}

.progress-class .classes-card .status-tag label {
    background-color: #ff974f;
    color: #ffffff;
}

.progress-class .event-card .date-time-sec {
    border-color: #ffa568;
}

.progress-class .event-card .classes-card .course-heading h4 {
    color: #ff6800;
}

.progress-class .event-card .classes-card .course-heading p {
    color: #ff832e;
}

.progress-class .event-card .date-time-sec .date-sec span,
.progress-class .event-card .date-time-sec .time-sec span {
    color: #ff832e;
}

.progress-class .event-card .date-time-sec .date-sec p,
.progress-class .event-card .date-time-sec .time-sec p {
    color: #ff6800;
}


.holiday-class .event-card {
    background-color: #e8feff;
    border-color: #2ccad1;
}

.holiday-class .event-card .swapped-member-sec {
    display: block;
    margin-top: 10px;
}

.holiday-class .classes-card .status-tag label {
    background-color: #33c2c9;
    color: #ffffff;
}

.holiday-class .event-card .date-time-sec {
    border-color: #2ccad1;
}

.holiday-class .event-card .classes-card .course-heading h4 {
    color: #00adb5;
}

.holiday-class .event-card .classes-card .course-heading p {
    color: #48acb1;
}

.holiday-class .event-card .date-time-sec .date-sec span,
.holiday-class .event-card .date-time-sec .time-sec span {
    color: #48acb1;
}

.holiday-class .event-card .date-time-sec .date-sec p,
.holiday-class .event-card .date-time-sec .time-sec p {
    color: #00adb5;
}



.cancel-class .event-card {
    background-color: #ffe8e8;
    border-color: #d12c2c;
}

.cancel-class .event-card .swapped-member-sec {
    display: block;
    margin-top: 10px;
}

.cancel-class .classes-card .status-tag label {
    background-color: #c93333;
    color: #ffffff;
}

.cancel-class .event-card .date-time-sec {
    border-color: #d12c2c;
}

.cancel-class .event-card .classes-card .course-heading h4 {
    color: #db0000;
}

.cancel-class .event-card .classes-card .course-heading p {
    color: #b14848;
}

.cancel-class .event-card .date-time-sec .date-sec span,
.cancel-class .event-card .date-time-sec .time-sec span {
    color: #b14848;
}

.cancel-class .event-card .date-time-sec .date-sec p,
.cancel-class .event-card .date-time-sec .time-sec p {
    color: #db0000;
}


.reschedule-class .event-card {
    background-color: #fff1fd;
    border-color: #db006f;
}

.reschedule-class .event-card .swapped-member-sec {
    display: block;
    margin-top: 10px;
}

.reschedule-class .classes-card .status-tag label {
    background-color: #e1579d;
    color: #ffffff;
}

.reschedule-class .event-card .date-time-sec {
    border-color: #db006f;
}

.reschedule-class .event-card .classes-card .course-heading h4 {
    color: #db006f;
}

.reschedule-class .event-card .classes-card .course-heading p {
    color: #e1579d;
}

.reschedule-class .event-card .date-time-sec .date-sec span,
.reschedule-class .event-card .date-time-sec .time-sec span {
    color: #e1579d;
}

.reschedule-class .event-card .date-time-sec .date-sec p,
.reschedule-class .event-card .date-time-sec .time-sec p {
    color: #db006f;
}

.reschedule-class .swapped-member-sec .swapped-whith-sec p {
    color: #e1579d;
}

.reschedule-class .swapped-member-sec .swapped-whith-sec .swapped-date-sec label {
    color: #db006f;
}

.reschedule-class .swapped-member-sec .swapped-whith-sec .swapped-user img {
    background-color: #ffbddf;
}



.reassign-class .event-card {
    background-color: #f2fffb;
    border-color: #00db94;
}

.reassign-class .event-card .swapped-member-sec {
    display: block;
    margin-top: 10px;
}

.reassign-class .classes-card .status-tag label {
    background-color: #44cfa1;
    color: #ffffff;
}

.reassign-class .event-card .date-time-sec {
    border-color: #00db94;
}

.reassign-class .event-card .classes-card .course-heading h4 {
    color: #00db94;
}

.reassign-class .event-card .classes-card .course-heading p {
    color: #55b797;
}

.reassign-class .event-card .date-time-sec .date-sec span,
.reassign-class .event-card .date-time-sec .time-sec span {
    color: #55b797;
}

.reassign-class .event-card .date-time-sec .date-sec p,
.reassign-class .event-card .date-time-sec .time-sec p {
    color: #00db94;
}

.reassign-class .swapped-member-sec .swapped-whith-sec p {
    color: #55b797;
}

.reassign-class .swapped-member-sec .swapped-whith-sec .swapped-date-sec label {
    color: #00db94;
}

.reassign-class .swapped-member-sec .swapped-whith-sec .swapped-user img {
    background-color: #a1cdbe;
}



.buttons-block {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #cbd3ff;
    background-color: #e4e9ff;
    border-radius: 0 0 10px 10px;
}

.buttons-block button {
    background-color: #4559d2;
    padding: 6px 20px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
}

.extra-class-btn button.chakra-button {
    background-color: #4559d2;
    font-size: 18px;
    color: #fff;
    padding: 12px 25px;
    height: auto;
    border-radius: 6px;
}

.extra-class-btn {
    text-align: center;
    margin: 30px 0;
}

.extra-class-btn button.chakra-button:hover {
    background-color: #2c40b2;
}

.extra-class-btn button.chakra-button svg {
    margin-left: 6px;
}

.schedule-class-popup.chakra-modal__content {
    max-height: 100vh;
}

/*------------------ Schedule Page END ------------------*/



/*------------------ Chat Box Start ------------------*/

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #c7cfff;
}

.chatbox-block .row .lecture-left-block::-webkit-scrollbar {
    width: 10px;
}

.chatbox-block .row .lecture-left-block::-webkit-scrollbar-thumb {
    background-color: #c7cfff;
}



.chatbox-block {
    margin: 0 10px;
}

.chatbox-block .row .lecture-left-block {
    background-color: #dfe4ff;
    border-radius: 20px 0px 0px 20px;
    overflow-y: scroll;
    height: 80vh;
}

.chatbox-block .row .lecture-left-block .search-section .chakra-input__group input {
    background-color: #c7cfff;
    border: 1px solid #899aff;
    color: #000000;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box {
    border-bottom: 1px solid #b9c3ff;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box .lecture-content h6 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box .lecture-content p {
    font-size: 16px;
    margin-bottom: 10px;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box>div {
    display: inline-block;
    vertical-align: top;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box .lecture-content {
    width: 80%;
    padding-right: 30px;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box .lecture-date-time {
    color: #7a7a7a;
    font-size: 13px;
    font-weight: 600;
    width: 20%;
    display: inline-grid;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box .lecture-date-time span {
    margin-right: 10px;
}

.chatbox-block .row .lecture-left-block .lecture-list .lecture-box:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.chatbox-block .row .lecture-chat-list .student-chatbox {
    position: relative;
    margin-bottom: 50px;
}

.chatbox-block .row .lecture-left-block .lecture-list {
    margin: 0 20px;
}


.chatbox-block .row .lecture-chat-list::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.chatbox-block .row .lecture-chat-list::-webkit-scrollbar-thumb {
    background-color: #dddddd;
}

.chatbox-block .row .lecture-chat-list {
    background-color: #f3f3f3;
    padding: 30px;
    border-radius: 0px 20px 20px 0px;
    overflow-y: scroll;
    height: 80vh;
}

.chatbox-block .row .lecture-chat-list .student-chatbox .student-img {
    margin-right: 20px;
}

.chatbox-block .row .lecture-chat-list .student-chatbox .student-message {
    background-color: #fff;
    padding: 10px 16px;
    border-radius: 10px 10px 10px 0px;
    width: auto;

}

.chatbox-block .row .lecture-chat-list .student-chatbox div {
    display: inline-block;
}

.chatbox-block .row .lecture-chat-list .student-chatbox .chat-time-sec {
    display: block;
    color: #b3b3b3;
    position: absolute;
    left: 70px;
    font-size: 12px;
    bottom: -26px;
}

.chatbox-block .row .lecture-chat-list .student-chatbox .student-message .student-name {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #a7a7a7;
    font-weight: 700;
}



.seactch-dater-filter {
    /* margin-bottom: 30px; */
    position: sticky;
    top: 0;
    background-color: #dfe4ff;
    padding: 20px 20px;
    z-index: 1;
}

.seactch-dater-filter>div {
    display: inline-block;
}

.seactch-dater-filter .search-section {
    width: 50%;
    padding-right: 10px;
}

.seactch-dater-filter .date-section {
    width: 50%;
    padding-left: 10px;
}

.seactch-dater-filter .date-section input.chakra-input {
    background-color: #c7cfff;
    border: 1px solid #899aff;
    color: #000000;
}
.seactch-dater-filter .ant-picker-outlined {
    background: #c4cfff;
    border-width: 1px;
    border-style: solid;
    border-color: #9baffe;
    padding: 10px 10px;
}
.seactch-dater-filter .ant-picker-outlined:hover {
    border-color: #9baffe;
    background-color: #c4cfff;
}


/*------------------ Chat Box END ------------------*/





@media only screen and (max-width: 768px) {

    .side-filters,
    .sidebar,
    .filter-button,
    .common-header,
    .mobile-hide {
        visibility: hidden;
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .mobile-common-header {
        visibility: hidden;
        display: none;
    }
}

.offer-background {
    background: #56ccf2;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2f80ed, #56ccf2);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2f80ed, #56ccf2);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.annotationLayer>a {
    display: block;
    position: absolute;
}

.annotationLayer>a:hover {
    opacity: 0.2;
    background: #ff0;
    box-shadow: 0px 2px 10px #ff0;
}

.annotText>div {
    z-index: 200;
    position: absolute;
    padding: 0.6em;
    max-width: 20em;
    background-color: #ffff99;
    box-shadow: 0px 2px 10px #333;
    border-radius: 7px;
}

.annotText>img {
    position: absolute;
    opacity: 0.6;
}

.annotText>img:hover {
    opacity: 1;
}

.annotText>div>h1 {
    font-size: 1.2em;
    border-bottom: 1px solid #000000;
    margin: 0px;
}

:root {
    --rpv-core__annotation--link-hover-background-color: rgba(255, 255, 0, 0.2);
    --rpv-core__annotation-popup-wrapper-background-color: #faf089;
    --rpv-core__annotation-popup-wrapper-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --rpv-core__annotation-popup-content-border-top-color: #1a202c;
    --rpv-core__arrow-border-color: rgba(0, 0, 0, 0.3);
    --rpv-core__asking-password-color: #000;
    --rpv-core__asking-password-wrapper-background-color: #fff;
    --rpv-core__asking-password-wrapper-border-color: rgba(0, 0, 0, 0.3);
    --rpv-core__button-background-color: rgba(0, 0, 0, 0.3);
    --rpv-core__button-color: #000;
    --rpv-core__doc-error-background-color: #fff;
    --rpv-core__doc-error-text-background-color: #c02424;
    --rpv-core__doc-error-text-color: #fff;
    --rpv-core__doc-loading-background-color: #fff;
    --rpv-core__inner-page-background-color: #fff;
    --rpv-core__menu-divider-border-bottom-color: rgba(0, 0, 0, 0.3);
    --rpv-core__menu-item-color: #000;
    --rpv-core__menu-item--hover-background-color: rgba(0, 0, 0, 0.1);
    --rpv-core__menu-item--disabled-color: rgba(0, 0, 0, 0.3);
    --rpv-core__minimal-button-color: #000;
    --rpv-core__minimal-button--hover-background-color: rgba(0, 0, 0, 0.1);
    --rpv-core__minimal-button--disabled-color: rgba(0, 0, 0, 0.3);
    --rpv-core__minimal-button--selected-background-color: rgba(0, 0, 0, 0.1);
    --rpv-core__modal-body-background-color: #fff;
    --rpv-core__modal-body-border-color: rgba(0, 0, 0, 0.3);
    --rpv-core__modal-overlay-background-color: rgba(0, 0, 0, 0.5);
    --rpv-core__page-layer-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
    --rpv-core__popover-body-background-color: #fff;
    --rpv-core__popover-body-border-color: rgba(0, 0, 0, 0.3);
    --rpv-core__popover-body-color: #000;
    --rpv-core__primary-button-background-color: #2566e8;
    --rpv-core__primary-button-color: #fff;
    --rpv-core__progress-bar-background-color: rgba(0, 0, 0, 0.1);
    --rpv-core__progress-bar-progress-background-color: #2566e8;
    --rpv-core__progress-bar-progress-color: #fff;
    --rpv-core__separator-border-bottom-color: rgba(0, 0, 0, 0.3);
    --rpv-core__spinner-border-color: rgba(0, 0, 0, 0.4);
    --rpv-core__spinner-border-transparent-color: transparent;
    --rpv-core__splitter-background-color: transparent;
    --rpv-core__splitter--hover-background-color: rgba(0, 0, 0, 0.2);
    --rpv-core__text-layer-text--selection-background-color: #00f;
    --rpv-core__text-layer-text--selection-color: transparent;
    --rpv-core__textbox-background-color: #fff;
    --rpv-core__textbox-border-color: rgba(0, 0, 0, 0.2);
    --rpv-core__textbox-color: #000;
    --rpv-core__tooltip-body-background-color: #000;
    --rpv-core__tooltip-body-color: #fff;
}

.rpv-core__viewer--dark {
    --rpv-core__asking-password-background-color: #363636;
    --rpv-core__asking-password-color: #fff;
    --rpv-core__asking-password-wrapper-border-color: #191919;
    --rpv-core__asking-password-wrapper-background-color: #191919;
    --rpv-core__button-background-color: #171717;
    --rpv-core__button-color: #fff;
    --rpv-core__doc-error-background-color: #191919;
    --rpv-core__doc-error-text-background-color: #c02323;
    --rpv-core__doc-error-text-color: #fff;
    --rpv-core__doc-loading-background-color: #191919;
    --rpv-core__inner-page-background-color: #1a1a1a;
    --rpv-core__menu-divider-border-bottom-color: #000;
    --rpv-core__menu-item-color: #fff;
    --rpv-core__menu-item--hover-background-color: #2566e8;
    --rpv-core__menu-item--disabled-color: #5e5e5e;
    --rpv-core__minimal-button-color: #fff;
    --rpv-core__minimal-button--disabled-color: #5e5e5e;
    --rpv-core__minimal-button--hover-background-color: #191919;
    --rpv-core__minimal-button--selected-background-color: #1657bb;
    --rpv-core__modal-body-background-color: #363636;
    --rpv-core__primary-button-background-color: #2566e8;
    --rpv-core__popover-body-background-color: #363636;
    --rpv-core__popover-body-color: #fff;
    --rpv-core__progress-bar-background-color: #000;
    --rpv-core__separator-border-bottom-color: #000;
    --rpv-core__spinner-border-color: #fff;
    --rpv-core__splitter-background-color: #1a1a1a;
    --rpv-core__splitter--hover-background-color: #2566e8;
    --rpv-core__textbox-background-color: #121212;
    --rpv-core__textbox-border-color: #121212;
    --rpv-core__textbox-color: #fff;
    --rpv-core__tooltip-body-background-color: #414141;
    --rpv-core__tooltip-body-color: #fff;
}

.rpv-core__annotation,
.rpv-core__arrow {
    position: absolute;
}

.rpv-core__arrow {
    border-bottom: 1px solid var(--rpv-core__arrow-border-color);
    border-left-color: var(--rpv-core__arrow-border-color);
    border-right: 1px solid var(--rpv-core__arrow-border-color);
    border-top-color: var(--rpv-core__arrow-border-color);
    height: 10px;
    width: 10px;
    z-index: 0;
}

.rpv-core__arrow--tl {
    bottom: 0;
    left: 0;
    transform: translate(50%, 50%) rotate(45deg);
}

.rpv-core__arrow--tc {
    left: 50%;
}

.rpv-core__arrow--tc,
.rpv-core__arrow--tr {
    bottom: 0;
    transform: translate(-50%, 50%) rotate(45deg);
}

.rpv-core__arrow--tr {
    right: 0;
}

.rpv-core__arrow--rt {
    left: 0;
    top: 0;
    transform: translate(-50%, 50%) rotate(135deg);
}

.rpv-core__arrow--rc {
    top: 50%;
}

.rpv-core__arrow--rb,
.rpv-core__arrow--rc {
    left: 0;
    transform: translate(-50%, -50%) rotate(135deg);
}

.rpv-core__arrow--rb {
    bottom: 0;
}

.rpv-core__arrow--bl {
    left: 0;
    top: 0;
    transform: translate(50%, -50%) rotate(225deg);
}

.rpv-core__arrow--bc {
    left: 50%;
}

.rpv-core__arrow--bc,
.rpv-core__arrow--br {
    top: 0;
    transform: translate(-50%, -50%) rotate(225deg);
}

.rpv-core__arrow--br {
    right: 0;
}

.rpv-core__arrow--lt {
    right: 0;
    top: 0;
    transform: translate(50%, 50%) rotate(315deg);
}

.rpv-core__arrow--lc {
    top: 50%;
}

.rpv-core__arrow--lb,
.rpv-core__arrow--lc {
    right: 0;
    transform: translate(50%, -50%) rotate(315deg);
}

.rpv-core__arrow--lb {
    bottom: 0;
}

.rpv-core__asking-password {
    background-color: var(--rpv-core__asking-password-background-color);
    border-radius: 0.25rem;
    color: var(--rpv-core__asking-password-color);
    padding: 2rem;
}

.rpv-core__asking-password--rtl {
    direction: rtl;
}

.rpv-core__asking-password-wrapper {
    align-items: center;
    background-color: var(--rpv-core__asking-password-wrapper-background-color);
    border: 1px solid var(--rpv-core__asking-password-wrapper-border-color);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.rpv-core__asking-password-message {
    margin: 0.5rem 0;
}

.rpv-core__asking-password-body {
    align-items: center;
    display: flex;
    justify-content: center;
}

.rpv-core__asking-password-input {
    width: 15rem;
}

.rpv-core__asking-password-input--ltr {
    margin-right: 0.5rem;
}

.rpv-core__asking-password-input--rtl {
    margin-left: 0.5rem;
}

.rpv-core__button {
    background-color: var(--rpv-core__button-background-color);
    border: none;
    border-radius: 0.25rem;
    color: var(--rpv-core__button-color);
    cursor: pointer;
    height: 2rem;
    padding: 0 1rem;
}

.rpv-core__button--rtl {
    direction: rtl;
}

.rpv-core__canvas-layer {
    direction: ltr;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
}

.rpv-core__doc-error {
    align-items: center;
    background-color: var(--rpv-core__doc-error-background-color);
    display: flex;
    height: 100%;
    justify-content: center;
}

.rpv-core__doc-error--rtl {
    direction: rtl;
}

.rpv-core__doc-error-text {
    background-color: var(--rpv-core__doc-error-text-background-color);
    border-radius: 0.25rem;
    color: var(--rpv-core__doc-error-text-color);
    line-height: 1.5;
    max-width: 50%;
    padding: 0.5rem;
}

.rpv-core__doc-loading {
    align-items: center;
    background-color: var(--rpv-core__doc-loading-background-color);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.rpv-core__doc-loading--rtl {
    direction: rtl;
}

.rpv-core__icon {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1;
    text-align: center;
}

.rpv-core__icon--rtl {
    transform: scaleX(-1);
}

.rpv-core__inner-pages--rtl {
    direction: rtl;
}

.rpv-core__inner-page {
    background-color: var(--rpv-core__inner-page-background-color);
    padding: 0.5rem;
}

.rpv-core__annotation--link a {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.rpv-core__annotation--link a:hover {
    background-color: var(--rpv-core__annotation--link-hover-background-color);
}

.rpv-core__menu {
    display: flex;
    flex-direction: column;
}

.rpv-core__menu--rtl {
    direction: rtl;
    text-align: right;
}

.rpv-core__menu-divider {
    border-bottom: 1px solid var(--rpv-core__menu-divider-border-bottom-color);
    margin: 0.25rem 0;
}

.rpv-core__menu-item {
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--rpv-core__menu-item-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.25rem 0;
    width: 100%;
}

.rpv-core__menu-item:focus {
    outline: 0;
}

.rpv-core__menu-item:focus,
.rpv-core__menu-item:hover {
    background-color: var(--rpv-core__menu-item--hover-background-color);
}

.rpv-core__menu-item-icon {
    align-items: center;
    display: flex;
}

.rpv-core__menu-item-icon--ltr {
    padding-left: 1rem;
    padding-right: 0.5rem;
}

.rpv-core__menu-item-icon--rtl {
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.rpv-core__menu-item-label {
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
}

.rpv-core__menu-item-label--ltr {
    padding-right: 2rem;
}

.rpv-core__menu-item-label--rtl {
    padding-left: 2rem;
}

.rpv-core__menu-item-check--ltr {
    padding-right: 1rem;
}

.rpv-core__menu-item-check--rtl {
    padding-left: 1rem;
}

.rpv-core__menu-item--disabled {
    color: var(--rpv-core__menu-item--disabled-color);
}

.rpv-core__menu-item--disabled:hover {
    background-color: transparent;
}

.rpv-core__menu-item--ltr {
    text-align: left;
}

.rpv-core__menu-item--rtl {
    direction: rtl;
    text-align: right;
}

.rpv-core__minimal-button {
    background-color: transparent;
    border: none;
    border-radius: 0.25rem;
    color: var(--rpv-core__minimal-button-color);
    cursor: pointer;
    height: 2rem;
    padding: 0 0.5rem;
}

.rpv-core__minimal-button:hover {
    background-color: var(--rpv-core__minimal-button--hover-background-color);
}

.rpv-core__minimal-button--disabled {
    color: var(--rpv-core__minimal-button--disabled-color);
}

.rpv-core__minimal-button--rtl {
    direction: rtl;
}

.rpv-core__minimal-button--selected {
    background-color: var(--rpv-core__minimal-button--selected-background-color);
}

.rpv-core__modal-body {
    background-color: var(--rpv-core__modal-body-background-color);
    border: 1px solid var(--rpv-core__modal-body-border-color);
    border-radius: 0.25rem;
    margin: 1rem;
    max-width: 32rem;
    overflow: auto;
}

.rpv-core__modal-body--rtl {
    direction: rtl;
}

.rpv-core__modal-overlay {
    background-color: var(--rpv-core__modal-overlay-background-color);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
}

.rpv-core__modal-overlay,
.rpv-core__page-layer {
    align-items: center;
    display: flex;
    justify-content: center;
}

.rpv-core__page-layer {
    box-shadow: var(--rpv-core__page-layer-box-shadow);
    margin: 0 auto;
    position: relative;
}

.rpv-core__page-size-calculator {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.rpv-core__popover-body {
    border: 1px solid var(--rpv-core__popover-body-border-color);
    border-radius: 0.25rem;
    color: var(--rpv-core__popover-body-color);
    left: 0;
    padding: 0.5rem 0;
    position: absolute;
    top: -9999px;
    z-index: 9999;
}

.rpv-core__popover-body,
.rpv-core__popover-body-arrow {
    background-color: var(--rpv-core__popover-body-background-color);
}

.rpv-core__popover-body--rtl {
    direction: rtl;
}

.rpv-core__popover-overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.rpv-core__annotation-popup-wrapper {
    background-color: var(--rpv-core__annotation-popup-wrapper-background-color);
    box-shadow: var(--rpv-core__annotation-popup-wrapper-box-shadow);
    font-size: 0.75rem;
    padding: 0.25rem;
    word-break: break-word;
}

.rpv-core__annotation-popup-wrapper--rtl {
    direction: rtl;
}

.rpv-core__annotation-popup-title {
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.rpv-core__annotation-popup-date {
    font-size: 0.5rem;
}

.rpv-core__annotation-popup-content {
    border-top: 1px solid var(--rpv-core__annotation-popup-content-border-top-color);
    max-height: 16rem;
    overflow: auto;
    padding: 0.25rem;
}

.rpv-core__primary-button {
    background-color: var(--rpv-core__primary-button-background-color);
    border: none;
    border-radius: 0.25rem;
    color: var(--rpv-core__primary-button-color);
    cursor: pointer;
    height: 2rem;
    padding: 0 1rem;
}

.rpv-core__primary-button--rtl {
    direction: rtl;
}

.rpv-core__progress-bar {
    background-color: var(--rpv-core__progress-bar-background-color);
    border-radius: 9999px;
    padding: 0.125rem;
}

.rpv-core__progress-bar--rtl {
    direction: rtl;
}

.rpv-core__progress-bar-progress {
    align-items: center;
    background-color: var(--rpv-core__progress-bar-progress-background-color);
    border-radius: 9999px;
    color: var(--rpv-core__progress-bar-progress-color);
    display: flex;
    font-size: 0.75rem;
    height: 0.75rem;
    justify-content: center;
}

.rpv-core__separator {
    border-bottom: 1px solid var(--rpv-core__separator-border-bottom-color);
}

.rpv-core__spinner {
    border-bottom: 2px solid var(--rpv-core__spinner-border-transparent-color);
    border-left: 2px solid var(--rpv-core__spinner-border-transparent-color);
    border-radius: 9999px;
    border-right: 2px solid var(--rpv-core__spinner-border-color);
    border-top: 2px solid var(--rpv-core__spinner-border-color);
}

.rpv-core__spinner--animating {
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    animation-name: rpv-core__spinner-transform;
    animation-timing-function: linear;
}

@keyframes rpv-core__spinner-transform {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}

.rpv-core__splitter {
    background-color: var(--rpv-core__splitter-background-color);
    cursor: ew-resize;
    height: 100%;
    width: 0.25rem;
}

.rpv-core__splitter--resizing,
.rpv-core__splitter:hover {
    background-color: var(--rpv-core__splitter--hover-background-color);
    cursor: col-resize;
}

.rpv-core__splitter-body--resizing {
    cursor: col-resize;
}

.rpv-core__splitter-sibling--resizing {
    pointer-events: none;
    user-select: none;
}

.rpv-core__textbox {
    background-color: var(--rpv-core__textbox-background-color);
    border: 1px solid var(--rpv-core__textbox-border-color);
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: var(--rpv-core__textbox-color);
    height: 2rem;
    padding: 0 0.5rem;
    width: 100%;
}

.rpv-core__textbox--rtl {
    direction: rtl;
}

.rpv-core__text-layer {
    height: 100%;
    left: 0;
    line-height: 1;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
}

.rpv-core__text-layer ::selection {
    background-color: var(--rpv-core__text-layer-text--selection-background-color);
    color: var(--rpv-core__text-layer-text--selection-color);
}

.rpv-core__text-layer-text {
    color: transparent;
    cursor: text;
    position: absolute;
    transform-origin: 0 0;
    white-space: pre;
}

.rpv-core__tooltip-body {
    background-color: var(--rpv-core__tooltip-body-background-color);
    border-radius: 0.25rem;
    color: var(--rpv-core__tooltip-body-color);
    left: 0;
    max-width: 20rem;
    position: absolute;
    text-align: center;
    top: -9999px;
    z-index: 9999;
}

.rpv-core__tooltip-body--rtl {
    direction: rtl;
}

.rpv-core__tooltip-body-arrow {
    background-color: var(--rpv-core__tooltip-body-background-color);
}

.rpv-core__tooltip-body-content {
    padding: 0.5rem;
}

.rpv-core__display--block {
    display: block;
}

.rpv-core__display--hidden {
    display: none;
}

@media (min-width: 640px) {
    .rpv-core__display--hidden-small {
        display: none;
    }

    .rpv-core__display--block-small {
        display: block;
    }
}

@media (min-width: 768px) {
    .rpv-core__display--hidden-medium {
        display: none;
    }

    .rpv-core__display--block-medium {
        display: block;
    }
}

@media (min-width: 1024px) {
    .rpv-core__display--hidden-large {
        display: none;
    }

    .rpv-core__display--block-large {
        display: block;
    }
}

:root {
    --rpv-full-screen__pages-background-color: #fff;
}

.rpv-core__viewer--dark {
    --rpv-full-screen__pages-background-color: #1a1a1a;
}

.rpv-full-screen__exit-button {
    bottom: 0;
    padding: 0.5rem;
    position: fixed;
    z-index: 1;
}

.rpv-full-screen__exit-button--ltr {
    right: 0;
}

.rpv-full-screen__exit-button--rtl {
    left: 0;
}

.rpv-full-screen__pages {
    background-color: var(--rpv-full-screen__pages-background-color);
}

.bottomHeader {}

/* HTML: <div class="loader"></div> */
.loader {
    width: 60px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, #000 90%, #0000) 0 / calc(100% / 3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1s steps(4) infinite;
}

.leaderBoardTable thead th {
    border-top: 1px solid #ABABAB;
    border-bottom: 1px solid #ABABAB;
    font-size: 13px;
    color: #000;
    font-weight: 500;
}
.leaderBoardTable td{
    text-transform: capitalize;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
}
.leaderBoardTable th {
    text-transform: capitalize;
    padding: 10px;
}
.leaderBoardTable{
    margin-top: 20px;
}

@keyframes l1 {
    to {
        clip-path: inset(0 -34% 0 0);
    }
}